import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { useAuthStore } from "@/stores/AuthStore";
import { backend } from "@/main";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: { title: "Home" },
  },
  {
    path: "/recovery",
    name: "recovery",
    component: () => import("../views/auth/RecoveryView.vue"),
    meta: { title: "Recovery" },
  },
  {
    path: "/update_password",
    name: "UpdatePassword",
    component: () => import("@/views/auth/UpdatePasswordView.vue"),
    meta: { title: "Update Password" },
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/ChatView.vue"),
    meta: { title: "Playground", showChatDetails: true },
  },

  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/PolicyView.vue"),
    meta: { title: "Policy" },
  },
  {
    path: "/logs",
    name: "logs",
    component: () => import("@/views/LogsView.vue"),
    meta: { title: "Logs" },
  },
  {
    path: "/prompts",
    name: "prompts",
    component: () => import("@/views/PromptsView.vue"),
    meta: { title: "Attacks" },
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("@/views/ReportsView.vue"),
    meta: { title: "Reports" },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/SettingsView.vue"),
    meta: { title: "Settings" },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/AccountSettingView.vue"),
    meta: { title: "Account Settings" },
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/auth/AuthView.vue"),
    meta: { title: "Auth" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/LoginView.vue"),
    meta: { title: "Login" },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/auth/SignupView.vue"),
    meta: { title: "Signup" },
  },
  {
    path: "/account-deletion-confirmation",
    name: "Account Deletion Confirmation",
    component: () => import("@/views/auth/AccountDeletionConfirmation.vue"),
    meta: { title: "Account Deletion Confirmation" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const authStore = useAuthStore();

  const publicPages = [
    "/login",
    "/signup",
    "/auth",
    "/recovery",
    "/account-deletion-confirmation",
  ];
  const authRequired =
    !publicPages.includes(to.path) && !to.path.startsWith("/update_password");

  // Send a heartbeat to the backend to keep the session alive.
  let uid = authStore.user?.uid;
  if (!uid) uid = "not_logged_in";

  let path = to.path.replace("/", "");
  if (!path) path = "home";
  backend.get(`heartbeat/${uid}/${encodeURIComponent(path)}`);

  if (!authStore.authIsReady || authRequired) {
    await authStore.init();
  }

  const shouldRedirectToAuth = await shouldRedirectToAuthentication(
    to,
    authStore,
    authRequired
  );
  if (shouldRedirectToAuth) return "/auth";

  const shouldRedirectToHome = await shouldRedirectToHomeIfLoggedIn(
    authRequired,
    authStore
  );
  if (shouldRedirectToHome) return "/";

  return true;
});

async function shouldRedirectToAuthentication(to, authStore, authRequired) {
  // check if we need to authenicate.
  if (authRequired && !authStore.user) {
    authStore.returnUrl = to.path;
    return true;
  }

  //Check if we are trying to access the page without email verification.
  if (authRequired && authStore.user && !authStore.user?.emailVerified) {
    authStore.returnUrl = to.path;
    return true;
  }
  // Check if the user is banned, if so, do not redirect to authentication
  if (authRequired && authStore.user) {
    if (authStore.isBanned === null) await authStore.getUserRole(); // BANNED = 1
    return authStore.isBanned;
  }
  return false;
}

async function shouldRedirectToHomeIfLoggedIn(authRequired, authStore) {
  // check if we are trying to access public page while logged in.
  if (!authRequired && authStore.user && authStore.user.token) {
    if (authStore.user?.emailVerified) {
      if (authStore.isBanned === null) await authStore.getUserRole(); // BANNED = 1
      return !authStore.isBanned;
    }
  }
  return false;
}

export default router;
