import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { useAuthStore } from "@/stores/AuthStore";

import VueApexCharts from "vue3-apexcharts";

import axios from "axios";
import "./assets/tailwind.css";

console.log(process.env.NODE_ENV);

export const backend = axios.create({
  baseURL: process.env.VUE_APP_API_BACKEND,
});

createApp(App).use(createPinia()).use(router).use(VueApexCharts).mount("#app");

const authStore = useAuthStore();
backend.interceptors.request.use(
  function (config) {
    const token = authStore.user?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
