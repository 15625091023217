import { defineAsyncComponent } from "vue";

// ! Import Icons and register them here

export const icons = {
  ZenguardLogoText: defineAsyncComponent(
    () => import("./assets/ZenguardLogoText.vue")
  ),
  GoogleLogo: defineAsyncComponent(() => import("./assets/GoogleLogo.vue")),
  Lock: defineAsyncComponent(() => import("./assets/Lock.vue")),
  ClosedEye: defineAsyncComponent(() => import("./assets/ClosedEye.vue")),
  LoadingSpinner: defineAsyncComponent(
    () => import("./assets/LoadingSpinner.vue")
  ),
  CheckMark: defineAsyncComponent(() => import("./assets/CheckMark.vue")),

  ZenGuard: defineAsyncComponent(() => import("./assets/ZenGuard.vue")),
  Home: defineAsyncComponent(() => import("./assets/Home.vue")),
  Chat: defineAsyncComponent(() => import("./assets/Chat.vue")),
  Policy: defineAsyncComponent(() => import("./assets/Policy.vue")),

  Shield: defineAsyncComponent(() => import("./assets/Shield.vue")),
  Graph: defineAsyncComponent(() => import("./assets/Graph.vue")),
  Gear: defineAsyncComponent(() => import("./assets/Gear.vue")),
  Logout: defineAsyncComponent(() => import("./assets/Logout.vue")),

  GithubLogo: defineAsyncComponent(() => import("./assets/GithubLogo.vue")),
  LinkedinLogo: defineAsyncComponent(() => import("./assets/LinkedinLogo.vue")),
  DiscordLogo: defineAsyncComponent(() => import("./assets/DiscordLogo.vue")),

  // Shared Icons
  Calendar: defineAsyncComponent(() => import("./assets/Calendar.vue")),
  OpenEye: defineAsyncComponent(() => import("./assets/OpenEye.vue")),
  Flag: defineAsyncComponent(() => import("./assets/Flag.vue")),

  ShevronUpDown: defineAsyncComponent(
    () => import("./assets/ShevronUpDown.vue")
  ),
  SquareStack: defineAsyncComponent(() => import("./assets/SquareStack.vue")),
  Star: defineAsyncComponent(() => import("./assets/Star.vue")),
  Book: defineAsyncComponent(() => import("./assets/Book.vue")),
  Dot: defineAsyncComponent(() => import("./assets/Dot.vue")),
  Plus: defineAsyncComponent(() => import("./assets/Plus.vue")),
  Cross: defineAsyncComponent(() => import("./assets/Cross.vue")),

  Block: defineAsyncComponent(() => import("./assets/Block.vue")),
  Warn: defineAsyncComponent(() => import("./assets/Warn.vue")),
  Info: defineAsyncComponent(() => import("./assets/Info.vue")),
  CheckMarkFilled: defineAsyncComponent(
    () => import("./assets/CheckMarkFilled.vue")
  ),
  TrashBin: defineAsyncComponent(() => import("./assets/TrashBin.vue")),
  PurpleStars: defineAsyncComponent(() => import("./assets/PurpleStars.vue")),
  Arrow: defineAsyncComponent(() => import("./assets/Arrow.vue")),
  CollapseSidebar: defineAsyncComponent(
    () => import("./assets/CollapseSidebar.vue")
  ),
  ExpandSidebar: defineAsyncComponent(
    () => import("./assets/ExpandSidebar.vue")
  ),
  CheckMarkPurple: defineAsyncComponent(
    () => import("./assets/CheckMarkPurple.vue")
  ),
  Tassel: defineAsyncComponent(() => import("./assets/Tassel.vue")),
  RedX: defineAsyncComponent(() => import("./assets/Red-X.vue")),
  LightBulb: defineAsyncComponent(() => import("./assets/LightBulb.vue")),
  ChatGPT: defineAsyncComponent(() => import("./assets/ChatGPT.vue")),
  ShevronPlaygroundChat: defineAsyncComponent(
    () => import("./assets/ShevronPlaygroundChat.vue")
  ),

  Meta: defineAsyncComponent(() => import("./assets/Meta.vue")),
  Claude: defineAsyncComponent(() => import("./assets/Claude.vue")),
  Gemini: defineAsyncComponent(() => import("./assets/Gemini.vue")),
  DotsVertical: defineAsyncComponent(() => import("./assets/DotsVertical.vue")),
  Pen: defineAsyncComponent(() => import("./assets/Pen.vue")),
  GraphEmptyData: defineAsyncComponent(
    () => import("./assets/GraphEmptyData.vue")
  ),
};

export type IconName = keyof typeof icons;

export interface IIconProps {
  name: IconName;
  styles?: string;
}
